<script setup lang="ts">
  import BigVerloopVlak from '../components/BigVerloopVlak.vue';
  import VlakImageBackground from '../components/VlakImageBackground.vue';
  import VlakBullets from '../components/VlakBullets.vue';
  import CenterQuote from '../components/CenterQuote.vue';
  import TwoVlakRowImageLeft from '../components/TwoVlakRowImageLeft.vue';
  import FourButtons from '../components/FourButtons.vue';
  import ZorgkaartReviews from '../components/ZorgkaartReviews.vue'
  import { useMeta } from 'vue-meta'

  useMeta({
    title: "Jouw psycholoog in de buurt – fysiek én online | Lenn",
    meta: [
      {
        name: "image", property: "og:image", content: "https://lenn-zorgt.nl/assets/OG_Image.png"
      },
      {
        name: 'description', property: 'og:description', content: 'Binnen enkele dagen een gesprek met een ervaren psycholoog. In onze spreekkamer én online. In Rotterdam, Delft, Lansingerland en Groningen. Regel het direct.'
      }
    ],
    og: {
      url: "https://lenn-zorgt.nl/assets/OG_Image.png",
      description: 'Binnen enkele dagen een gesprek met een ervaren psycholoog. In onze spreekkamer én online. In Rotterdam, Delft, Lansingerland en Groningen. Regel het direct.'
    }
  })
</script>

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <BigVerloopVlak/>
  <TwoVlakRowImageLeft>
    <template #left>
      <picture>
        <source type="image/webp" media="(min-width: 600px)" srcset="@/assets/images/pink-girl_landscape.webp">
        <source type="image/webp" media="(min-width: 300px)" srcset="@/assets/images/pink-girl_landscape_mobile.webp">
        <img loading="lazy" class="TwoVlakRowImage" src="@/assets/images/pink-girl_landscape.png"/>
      </picture>
    </template>
    <template #right>
      <div class="first-twovlak-right-text">
        <h2>Jouw psycholoog in de buurt</h2>
        <p>Binnen enkele dagen een goed gesprek met een psycholoog? Met èchte aandacht voor jou en de wereld om je heen.</p>
        <p>Misschien ben je al na één gesprek geholpen. Heb je meer nodig? Samen met ons werk je aan jouw thema’s, gericht op hoofd én lijf. </p>
        <p>Ons aanbod is kortdurend en intensief en helpt bij het vinden van een nieuw perspectief. Dat doen we met gesprekken, digitaal en in het groen. Zo brengen we de zorg dichtbij, tot in jouw broekzak.</p>
        <p>Je krijgt begeleiding van psychologen en een fysiotherapeut of psycho-motorisch therapeut. Want ook bewegen heeft invloed op jouw mentale gezondheid.</p>
      </div>
    </template>
  </TwoVlakRowImageLeft>
  <CenterQuote/>
  <VlakImageBackground hasCta="approach"/>
  <VlakBullets/>
  <TwoVlakRowImageLeft styles="lenn-overlay">
    <template #left>
      <picture>
        <source type="image/webp" media="(min-width: 600px)" srcset="@/assets/images/Lenn-6334b.webp">
        <source type="image/webp" media="(min-width: 300px)" srcset="@/assets/images/Lenn-6334b.webp">
        <img loading="lazy" class="TwoVlakRowImage" src="@/assets/images/Lenn-6334b.jpg">
      </picture>
    </template>
    <template #right>
      <div class="twovlak-right-text">
        <p>
          Bij Lenn geloven we dat de zorg voor mentale gezondheid in Nederland beter kan. Zonder wachtlijsten en zonder dat we mentaal ongemak altijd zien als een ziekte of aandoening. We focussen op wat helpend is voor jou.</p>
          <p>Persoonlijk, intensief en kortdurend.</p>
          <p>Met de nieuwste inzichten en innovaties bieden we een frisse kijk op jouw mentale én fysieke gezondheid.</p>
      </div>
    </template>
  </TwoVlakRowImageLeft>
  <FourButtons/>
  <ZorgkaartReviews/>
</template>

<style scoped>
  .cta {
    position: absolute;
    z-index: 1;
    bottom: 0;
    margin-bottom: 40px;
    left: calc(100% - 220px);
    display: flex;
    width: 210px;
  }

  .first-twovlak-right-text, .twovlak-right-text {
    max-width: 520px;
  }

  .first-twovlak-right-text h2 {
    color: var(--red);
  }

  .twovlak-right-text {
    color: white;
    font-weight: 100;
    margin-top: 30px;
    /* margin: 30px 30px 30px 30px; */
  }

  @media (max-width: 600px) {
  }
</style>