import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const AboutView = () => import('../views/AboutView.vue')
const WorkAtLennView = () => import('../views/WorkAtLennView.vue')
const JobView = () => import('../views/JobView.vue')
const ApproachView = () => import('../views/ApproachView.vue')
const ReferrersView = () => import('../views/ReferrersView.vue')
const ForWhoView = () => import('../views/ForWhoView.vue')
const TarifsView = () => import('../views/TarifsView.vue')
const EmployersView = () => import('../views/EmployersView.vue')
const ContactLocationsView = () => import('../views/ContactLocationsView.vue')
const PrivacyStatementView = () => import('../views/FooterViews/PrivacyStatementView.vue')
const CookieStatementView = () => import('../views/FooterViews/CookieStatementView.vue')
const TermsAndConditionsView = () => import('../views/FooterViews/TermsAndConditionsView.vue')
const RedirectSignUpViewVue = () => import('../views/RedirectSignUpView.vue')
const SignUpViewVue = () => import('../views/SignUpView.vue')
const SignInViewVue = () => import('../views/SignInView.vue')
const FourOFourView = () => import('../views/404Page.vue')

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/over-lenn',
      name: 'about',
      component: AboutView
    },
    {
      path: '/werken-bij-lenn',
      name: 'work-at-lenn',
      component: WorkAtLennView,
    },
    {
      path: '/werken-bij-lenn/:slug',
      name: 'job',
      component: JobView
    },
    {
      path: '/hoe-werkt-lenn',
      name: 'approach',
      component: ApproachView
    },
    {
      path: '/aanmelden',
      name: 'signup',
      component: SignUpViewVue
    },
    {
      path: '/aanmelden-redirect',
      name: 'signup-redirect',
      component: RedirectSignUpViewVue
    },
    {
      path: '/inloggen',
      name: 'signin',
      component: SignInViewVue
    },
    {
      path: '/verwijzers',
      name: 'referrer',
      component: ReferrersView
    },
    {
      path: '/voor-wie',
      name: 'for-who',
      component: ForWhoView
    },
    {
      path: '/tarieven-en-vergoedingen',
      name: 'tarifs',
      component: TarifsView
    },
    {
      path: '/werkgevers',
      name: 'employers',
      component: EmployersView
    },
    {
      path: '/contact',
      name: 'contact',
      component: ContactLocationsView
    },
    {
      path: '/privacyverklaring',
      name: 'privacystatment',
      component: PrivacyStatementView
    },
    {
      path: '/cookieverklaring',
      name: 'cookieverklaring',
      component: CookieStatementView
    },
    {
      path: '/algemene-voorwaarden',
      name: 'terms-and-conditions',
      component: TermsAndConditionsView
    },
    {
      path: "/:notFound",
      name: "Four0Four",
      component: FourOFourView
    }
  ]
})

export default router
