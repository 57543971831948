import { createApp } from 'vue'
import * as Sentry from "@sentry/vue"
import { createPinia } from 'pinia'
import { createMetaManager, defaultConfig } from 'vue-meta'

import Antd from "ant-design-vue"
import "ant-design-vue/dist/antd.css"

import App from './App.vue'
import router from './router'

import VueGtag from 'vue-gtag'

import './assets/main.css'

const app = createApp(App)
const metaManager = createMetaManager(false, { ...defaultConfig, meta: { tag: 'meta', nameless: true }})

Sentry.init({
  app,
  dsn: "https://4a40ceb13c506ed8ffe9e83c53fe8000@o4507531639521280.ingest.de.sentry.io/4507531643060304",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/lenn-zorgt\.nl/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


app.use(metaManager)
app.use(createPinia())
app.use(router)
app.use(VueGtag, {
  config: { id: 'G-RHY8WH9QQT' }
})
app.use(Antd)
app.mount('#app')