<script setup lang="ts">
  import { Row, Col } from 'ant-design-vue';
  import CallToActionButton from './CallToActionButton.vue';
  import BulletArrow from './BulletArrow.vue'

  const bulletTexts = [
    "Binnen enkele dagen een Goed Gesprek",
    "Kortdurend en intensief",
    "Gesprekken op locatie, in het groen én digitaal",
    "Werken aan jouw mentale én fysieke gezondheid"
    ]
</script>

<template>
<div class="container">
  <div class="content-container">
    <!-- <div class="title">
      <span>Ja, ik wil graag <br/>snel een gesprek</span>
    </div> -->
    <Row>
      <Col :xs="24" :sm="24" :md="24" :lg="17">
        <BulletArrow v-bind:key="text" v-for="text in bulletTexts" :text="text"/>
      </Col>
      <Col :xs="24" :sm="24" :md="24" :lg="7">
        <div class="cta-container">
          <div class="cta-button">
            <CallToActionButton linkto="/aanmelden">
              Boek direct een <br />Goed Gesprek
            </CallToActionButton>
          </div>
        </div>
      </Col>
    </Row>
  </div>
</div>
</template>

<style scoped>
  .container {
    background-color: white;
  }

  .cta-container {
    height: 100%;
    display: flex;
  }

  .content-container {
    width: 1040px;
    margin: auto;
    padding: 40px 20px 40px 20px;
  }

  .title {
    font-size: 55px;
    color: var(--magenta);
    margin-bottom: 70px;
    line-height: 55px;
    font-weight: 100;
  }

  .cta-button {
    align-items: end;
    display: flex;
    margin-top: 20px;
  }

  @media (max-width: 800px) {
    .content-container {
      padding: 50px 10px 50px 20px;
      width: 100%;
    }

    .cta-button {
      margin-top: 50px;
    }
    .title {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 30px;
    }
  }
</style>